// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("jquery")
//= require rails-ujs
//= require jquery
//= require tinymce-jquery

require("@rails/ujs").start()

window.jQuery = window.$ = require('jquery')
//СЕЛЕКТ
require("packs/select2")
require("packs/topbox")


//ЗАГРУЗКА КАРТИНОК
require("packs/jquery.fileuploader")

//ВАЛИДАЦИЯ
require("packs/parsley/parsley.min")
require("packs/parsley/i18n/ru")

require("./jquery.menu-aim")
require("./bootstrap.bundle.min")
require("./plugins")
//require("packs/magnific")

import "../controllers"
import Swiper, {Navigation, Pagination, Scrollbar, Thumbs, Mousewheel, EffectCards} from 'swiper';
// import topbox  from 'topbox';
import tippy from 'tippy.js';
import noUiSlider from 'nouislider';

//Поп-апы
let app = {
    initTopbox: function ($elements) {
        // iFrames require a data-lightbox-type="iframe" attribute.
        // AJAX requires a data-lightbox-type="ajax" attribute.
        // Inline requires a data-lightbox-type="inline" attribute.
        // Video iFrames require a data-lightbox-type="video-iframe" attribute.
        if ($elements.length) {
            $elements.topbox({
                keyboardNav: false,
                beforeShowLightbox: function (lightbox) {
                    $('.topbox_overlay').remove();
                },
                afterShowLightbox: function (lightbox) {
                    $('body').on('click', '.js-close-topbox', function () {
                        $('.topbox_close').trigger('click');
                    });
                }
            });
        }
    },
    openTopbox: function (url) {
        var $link = $('<a href="' + url + '" class="js-topbox" data-lightbox-type="ajax" aria-haspopup="dialog"></a>');
        this.initTopbox($link);
        $link
            .click()
            .remove();
    }
};

$(document).ready(function () {
    app.initTopbox($('.js-topbox'));

    // enable file uploader plugin https://innostudio.de/fileuploader/documentation/#examples
    $('input.js_load').fileuploader({
        captions: 'ru',
        addMore: true,
        enableApi: true,
        inputNameBrackets: true,
        thumbnails: {
            onItemShow: function (item) {
                // add sorter button to the item html
                item.html.find('.fileuploader-action-remove').before('<button type="button" class="fileuploader-action fileuploader-action-sort" title="Sort"><i class="fileuploader-icon-sort"></i></button><div class="js_thistext fileuploader-action"></div>');
                item.html.find('.js_thistext').append(item.html.closest('label').find('.image-sortable>.image:first-child'));
            }
        },
        sorter: {
            selectorExclude: null,
            placeholder: null,
            scrollContainer: window,
            onSort: function (list, listEl, parentEl, newInputEl, inputEl) {
            }
        }
    });
    $('input.js_load_avatar').fileuploader({
        limit: 2,
        extensions: ['image/*'],
        fileMaxSize: 10,
        changeInput: ' ',
        theme: 'avatar',
        addMore: true,
        enableApi: true,
        thumbnails: {
            box: '<div class="fileuploader-wrapper">' +
                '<div class="fileuploader-items"></div>' +
                '<div class="fileuploader-droparea" data-action="fileuploader-input"><i class="fileuploader-icon-main"></i></div>' +
                '</div>' +
                '<div class="fileuploader-menu">' +
                '<button type="button" class="fileuploader-menu-open"><i class="fileuploader-icon-menu"></i></button>' +
                '<ul>' +
                '<li><a data-action="fileuploader-input"><i class="fileuploader-icon-upload"></i> ${captions.upload}</a></li>' +
                '<li><a data-action="fileuploader-edit"><i class="fileuploader-icon-edit"></i> ${captions.edit}</a></li>' +
                '<li><a data-action="fileuploader-remove"><i class="fileuploader-icon-trash"></i> ${captions.remove}</a></li>' +
                '</ul>' +
                '</div>',
            item: '<div class="fileuploader-item">' +
                '${image}' +
                '<span class="fileuploader-action-popup" data-action="fileuploader-edit"></span>' +
                '<div class="progressbar3" style="display: none"></div>' +
                '</div>',
            item2: null,
            itemPrepend: true,
            startImageRenderer: true,
            canvasImage: false,
            _selectors: {
                list: '.fileuploader-items'
            },
            popup: {
                arrows: false,
                onShow: function (item) {
                    item.popup.html.addClass('is-for-avatar');
                    item.popup.html.on('click', '[data-action="remove"]', function (e) {
                        item.popup.close();
                        item.remove();
                    }).on('click', '[data-action="cancel"]', function (e) {
                        item.popup.close();
                    }).on('click', '[data-action="save"]', function (e) {
                        if (item.editor && !item.isSaving) {
                            item.isSaving = true;
                            item.editor.save();
                        }
                        if (item.popup.close)
                            item.popup.close();
                    });
                },
                onHide: function (item) {
                    if (!item.isSaving && !item.uploaded && !item.appended) {
                        item.popup.close = null;
                        item.remove();
                    }
                }
            },
            onItemShow: function (item) {
                if (item.choosed)
                    item.html.addClass('is-image-waiting');
            },
            onImageLoaded: function (item, listEl, parentEl, newInputEl, inputEl) {
                if (item.choosed && !item.isSaving) {
                    if (item.reader.node && item.reader.width >= 256 && item.reader.height >= 256) {
                        item.image.hide();
                        item.popup.open();
                        item.editor.cropper();
                    } else {
                        item.remove();
                        alert('The image is too small!');
                    }
                } else if (item.data.isDefault)
                    item.html.addClass('is-default');
                else if (item.image.hasClass('fileuploader-no-thumbnail'))
                    item.html.hide();
            },
            onItemRemove: function (html) {
                html.fadeOut(250, function () {
                    html.remove();
                });
            }
        },
        dragDrop: {
            container: '.fileuploader-wrapper'
        },
        editor: {
            maxWidth: 512,
            maxHeight: 512,
            quality: 90,
            cropper: {
                showGrid: false,
                ratio: '1:1',
                minWidth: 256,
                minHeight: 256,
            },
            onSave: function (base64, item, listEl, parentEl, newInputEl, inputEl) {
                var api = $.fileuploader.getInstance(inputEl);

                if (!base64)
                    return;

                // blob
                item.editor._blob = api.assets.dataURItoBlob(base64, item.type);

                if (item.upload) {
                    if (api.getFiles().length == 2 && (api.getFiles()[0].data.isDefault || api.getFiles()[0].upload))
                        api.getFiles()[0].remove();
                    parentEl.find('.fileuploader-menu ul a').show();

                    if (item.upload.send)
                        return item.upload.send();
                    if (item.upload.resend)
                        return item.upload.resend();
                } else if (item.appended) {
                    var form = new FormData();

                    // hide current thumbnail (this is only animation)
                    item.image.addClass('fileuploader-loading').html('');
                    item.html.find('.fileuploader-action-popup').hide();
                    parentEl.find('[data-action="fileuploader-edit"]').hide();

                    // send ajax
                    form.append(inputEl.attr('name'), item.editor._blob);
                    form.append('fileuploader', true);
                    form.append('name', item.name);
                    form.append('editing', true);
                    $.ajax({
                        url: api.getOptions().upload.url,
                        data: form,
                        type: 'POST',
                        processData: false,
                        contentType: false
                    }).always(function () {
                        delete item.isSaving;
                        item.reader.read(function () {
                            item.html.find('.fileuploader-action-popup').show();
                            parentEl.find('[data-action="fileuploader-edit"]').show();
                            item.popup.html = item.popup.node = item.popup.editor = item.editor.crop = item.editor.rotation = item.popup.zoomer = null;
                            item.renderThumbnail();
                        }, null, true);
                    });
                }
            }
        },
        upload: {
            url: '/profile/ajax_upload_file' + "?&authenticity_token=" + document.querySelector("meta[name='csrf-token']").content,
            data: null, // should be null
            type: 'POST',
            enctype: 'multipart/form-data',
            start: false,
            beforeSend: function (item, listEl, parentEl, newInputEl, inputEl) {
                item.upload.formData = new FormData();

                if (item.editor && item.editor._blob) {
                    item.upload.data.fileuploader = 1;
                    item.upload.data.name = item.name;
                    item.upload.data.editing = item.uploaded;

                    item.upload.formData.append(inputEl.attr('name'), item.editor._blob, item.name);
                }

                item.image.hide();
                item.html.removeClass('upload-complete');
                parentEl.find('[data-action="fileuploader-edit"]').hide();
                this.onProgress({percentage: 0}, item);
            },
            onSuccess: function (result, item, listEl, parentEl, newInputEl, inputEl) {
                var api = $.fileuploader.getInstance(inputEl),
                    $progressBar = item.html.find('.progressbar3'),
                    data = {};

                if (result && result.files)
                    data = result;
                else
                    data.hasWarnings = true;

                if (api.getFiles().length > 1)
                    api.getFiles()[0].remove();

                // if success
                if (data.isSuccess && data.files[0]) {
                    item.name = data.files[0].name;
                }

                // if warnings
                if (data.hasWarnings) {
                    for (var warning in data.warnings) {
                        alert(data.warnings[warning]);
                    }

                    item.html.removeClass('upload-successful').addClass('upload-failed');
                    return this.onError ? this.onError(item) : null;
                }

                delete item.isSaving;
                item.html.addClass('upload-complete').removeClass('is-image-waiting');
                $progressBar.find('span').html('<i class="fileuploader-icon-success"></i>');
                parentEl.find('[data-action="fileuploader-edit"]').show();
                setTimeout(function () {
                    $progressBar.fadeOut(450);
                }, 1250);
                item.image.fadeIn(250);
            },
            onError: function (item, listEl, parentEl, newInputEl, inputEl) {
                var $progressBar = item.html.find('.progressbar3');

                item.html.addClass('upload-complete');
                if (item.upload.status != 'cancelled')
                    $progressBar.find('span').attr('data-action', 'fileuploader-retry').html('<i class="fileuploader-icon-retry"></i>');
            },
            onProgress: function (data, item) {
                var $progressBar = item.html.find('.progressbar3');

                if (data.percentage == 0)
                    $progressBar.addClass('is-reset').fadeIn(250).html('');
                else if (data.percentage >= 99)
                    data.percentage = 100;
                else
                    $progressBar.removeClass('is-reset');
                if (!$progressBar.children().length)
                    $progressBar.html('<span></span><svg><circle class="progress-dash"></circle><circle class="progress-circle"></circle></svg>');

                var $span = $progressBar.find('span'),
                    $svg = $progressBar.find('svg'),
                    $bar = $svg.find('.progress-circle'),
                    hh = Math.max(60, item.html.height() / 2),
                    radius = Math.round(hh / 2.28),
                    circumference = radius * 2 * Math.PI,
                    offset = circumference - data.percentage / 100 * circumference;

                $svg.find('circle').attr({
                    r: radius,
                    cx: hh,
                    cy: hh
                });
                $bar.css({
                    strokeDasharray: circumference + ' ' + circumference,
                    strokeDashoffset: offset
                });

                $span.html(data.percentage + '%');
            },
            onComplete: null,
        },
        afterRender: function (listEl, parentEl, newInputEl, inputEl) {
            var api = $.fileuploader.getInstance(inputEl);

            // remove multiple attribute
            inputEl.removeAttr('multiple');

            // set drop container
            api.getOptions().dragDrop.container = parentEl.find('.fileuploader-wrapper');

            // disabled input
            if (api.isDisabled()) {
                parentEl.find('.fileuploader-menu').remove();
            }

            // [data-action]
            parentEl.on('click', '[data-action]', function () {
                var $this = $(this),
                    action = $this.attr('data-action'),
                    item = api.getFiles().length ? api.getFiles()[api.getFiles().length - 1] : null;

                switch (action) {
                    case 'fileuploader-input':
                        api.open();
                        break;
                    case 'fileuploader-edit':
                        if (item && item.popup) {
                            if (!$this.is('.fileuploader-action-popup'))
                                item.popup.open();
                            item.editor.cropper();
                        }
                        break;
                    case 'fileuploader-retry':
                        if (item && item.upload.retry)
                            item.upload.retry();
                        break;
                    case 'fileuploader-remove':
                        if (item)
                            item.remove();
                        break;
                }
            });

            // menu
            $('body').on('click', function (e) {
                var $target = $(e.target),
                    $parent = $target.closest('.fileuploader');

                $('.fileuploader-menu').removeClass('is-shown');
                if ($target.is('.fileuploader-menu-open') || $target.closest('.fileuploader-menu-open').length)
                    $parent.find('.fileuploader-menu').addClass('is-shown');
            });
        },
        onEmpty: function (listEl, parentEl, newInputEl, inputEl) {
            var api = $.fileuploader.getInstance(inputEl),
                defaultAvatar = inputEl.attr('data-fileuploader-default');

            if (defaultAvatar && !listEl.find('> .is-default').length)
                api.append({
                    name: '',
                    type: 'image/png',
                    size: 0,
                    file: defaultAvatar,
                    data: {isDefault: true, popup: false, listProps: {is_default: true}}
                });

            parentEl.find('.fileuploader-menu ul a').hide().filter('[data-action="fileuploader-input"]').show();
        },
        onRemove: function (item) {
            if (item.name && (item.appended || item.uploaded))
                $.post('/profile/ajax_remove_file' + "?&authenticity_token=" + document.querySelector("meta[name='csrf-token']").content, {
                    file: item.name
                })
        },
        // captions: 'ru',
        captions: $.extend(true, {}, $.fn.fileuploader.languages['en'], {
            edit: 'Изменить',
            upload: 'Загрузить',
            remove: 'Удалить',
            errors: {
                filesLimit: 'Разрешается загружать только 1 файл.',
            }
        })
    });
    $('input.js_load_list').fileuploader({
        captions: 'ru',
        addMore: true,
        enableApi: true,
        theme: 'thumbnails',
        extensions: null,
        limit: 12,
        changeInput: ' ',
        inputNameBrackets: true,
        thumbnails: {
            box: '<div class="fileuploader-items">' +
                '<ul class="fileuploader-items-list">' +
                '<li class="fileuploader-thumbnails-input"><div class="fileuploader-thumbnails-input-inner"><i>+</i></div></li>' +
                '</ul>' +
                '</div>',
            item: '<li class="fileuploader-item">' +
                '<div class="fileuploader-item-inner">' +
                '<div class="actions-holder">' +
                '<button type="button" class="fileuploader-action fileuploader-action-remove" title="${captions.remove}"><i class="fileuploader-icon-remove"></i></button>' +
                '</div>' +
                '<div class="thumbnail-holder">' +
                '${image}' +
                '<span class="fileuploader-action-popup"></span>' +
                '</div>' +
                '<div class="progress-holder">${progressBar}</div>' +
                '</div>' +
                '</li>',
            item2: '<li class="fileuploader-item">' +
                '<div class="fileuploader-item-inner">' +
                '<div class="actions-holder">' +
                '<a href="${file}" class="fileuploader-action fileuploader-action-download" title="${captions.download}" download><i class="fileuploader-icon-download"></i></a>' +
                '<button type="button" class="fileuploader-action fileuploader-action-remove" title="${captions.remove}"><i class="fileuploader-icon-remove"></i></button>' +
                '</div>' +
                '<div class="thumbnail-holder">' +
                '${image}' +
                '<span class="fileuploader-action-popup"></span>' +
                '</div>' +
                '<div class="content-holder"></div>' +
                '<div class="progress-holder">${progressBar}</div>' +
                '</div>' +
                '</li>',
            startImageRenderer: true,
            canvasImage: false,
            _selectors: {
                list: '.fileuploader-items-list',
                item: '.fileuploader-item',
                start: '.fileuploader-action-start',
                retry: '.fileuploader-action-retry',
                remove: '.fileuploader-action-remove'
            },
            onItemShow: function (item, listEl, parentEl, newInputEl, inputEl) {
                var plusInput = listEl.find('.fileuploader-thumbnails-input'),
                    api = $.fileuploader.getInstance(inputEl.get(0));

                // add sorter button to the item html
                //item.html.find('.fileuploader-action-remove').before('<button type="button" class="fileuploader-action fileuploader-action-sort" title="Sort"><i class="fileuploader-icon-sort"></i></button><div class="js_thistext fileuploader-action"></div>');
                //item.html.find('.js_thistext').append(item.html.closest('label').find('.image-sortable>.image:first-child'));

                plusInput.insertAfter(item.html)[api.getOptions().limit && api.getChoosedFiles().length >= api.getOptions().limit ? 'hide' : 'show']();

                if (item.format == 'image') {
                    item.html.find('.fileuploader-item-icon').hide();
                }
            },
            onItemRemove: function (html, listEl, parentEl, newInputEl, inputEl) {
                var plusInput = listEl.find('.fileuploader-thumbnails-input'),
                    api = $.fileuploader.getInstance(inputEl.get(0));

                html.children().animate({'opacity': 0}, 200, function () {
                    html.remove();

                    if (api.getOptions().limit && api.getChoosedFiles().length - 1 < api.getOptions().limit)
                        plusInput.show();
                });
            }
        },
        dragDrop: {
            container: '.fileuploader-thumbnails-input'
        },
        afterRender: function (listEl, parentEl, newInputEl, inputEl) {
            var plusInput = listEl.find('.fileuploader-thumbnails-input'),
                api = $.fileuploader.getInstance(inputEl.get(0));

            plusInput.on('click', function () {
                api.open();
            });

            api.getOptions().dragDrop.container = plusInput;
        },
        sorter: {
            selectorExclude: null,
            placeholder: null,
            scrollContainer: window,
            onSort: function (list, listEl, parentEl, newInputEl, inputEl) {
            }
        }
    });
    $('input.js_load_list_one').fileuploader({
        captions: 'ru',
        addMore: true,
        enableApi: true,
        theme: 'thumbnails',
        extensions: null,
        limit: 1,
        changeInput: ' ',
        inputNameBrackets: true,
        thumbnails: {
            box: '<div class="fileuploader-items">' +
                '<ul class="fileuploader-items-list">' +
                '<li class="fileuploader-thumbnails-input"><div class="fileuploader-thumbnails-input-inner"><i>+</i></div></li>' +
                '</ul>' +
                '</div>',
            item: '<li class="fileuploader-item">' +
                '<div class="fileuploader-item-inner">' +
                '<div class="actions-holder">' +
                '<button type="button" class="fileuploader-action fileuploader-action-remove" title="${captions.remove}"><i class="fileuploader-icon-remove"></i></button>' +
                '</div>' +
                '<div class="thumbnail-holder">' +
                '${image}' +
                '<span class="fileuploader-action-popup"></span>' +
                '</div>' +
                '<div class="progress-holder">${progressBar}</div>' +
                '</div>' +
                '</li>',
            item2: '<li class="fileuploader-item">' +
                '<div class="fileuploader-item-inner">' +
                '<div class="actions-holder">' +
                '<a href="${file}" class="fileuploader-action fileuploader-action-download" title="${captions.download}" download><i class="fileuploader-icon-download"></i></a>' +
                '<button type="button" class="fileuploader-action fileuploader-action-remove" title="${captions.remove}"><i class="fileuploader-icon-remove"></i></button>' +
                '</div>' +
                '<div class="thumbnail-holder">' +
                '${image}' +
                '<span class="fileuploader-action-popup"></span>' +
                '</div>' +
                '<div class="progress-holder">${progressBar}</div>' +
                '</div>' +
                '</li>',
            startImageRenderer: true,
            canvasImage: false,
            _selectors: {
                list: '.fileuploader-items-list',
                item: '.fileuploader-item',
                start: '.fileuploader-action-start',
                retry: '.fileuploader-action-retry',
                remove: '.fileuploader-action-remove'
            },
            onItemShow: function (item, listEl, parentEl, newInputEl, inputEl) {
                var plusInput = listEl.find('.fileuploader-thumbnails-input'),
                    api = $.fileuploader.getInstance(inputEl.get(0));

                plusInput.insertAfter(item.html)[api.getOptions().limit && api.getChoosedFiles().length >= api.getOptions().limit ? 'hide' : 'show']();

                if (item.format == 'image') {
                    item.html.find('.fileuploader-item-icon').hide();
                }
            },
            onItemRemove: function (html, listEl, parentEl, newInputEl, inputEl) {
                var plusInput = listEl.find('.fileuploader-thumbnails-input'),
                    api = $.fileuploader.getInstance(inputEl.get(0));

                html.children().animate({'opacity': 0}, 200, function () {
                    html.remove();

                    if (api.getOptions().limit && api.getChoosedFiles().length - 1 < api.getOptions().limit)
                        plusInput.show();
                });
            }
        },
        dragDrop: {
            container: '.fileuploader-thumbnails-input'
        },
        afterRender: function (listEl, parentEl, newInputEl, inputEl) {
            var plusInput = listEl.find('.fileuploader-thumbnails-input'),
                api = $.fileuploader.getInstance(inputEl.get(0));

            plusInput.on('click', function () {
                api.open();
            });

            api.getOptions().dragDrop.container = plusInput;
        }
    });
    $('input.js_load_one').fileuploader({
        captions: 'ru',
        changeInput: true,
        limit: 1,
        thumbnails: {}
    });


    // СЕЛЕКТ
    // 'data-placeholder': 'Выберите или добавьте бренд', 'data-tags':' true', 'data-ajax--url':'/brands/searchbrand', 'data-ajax--dataType':'json', 'data-width': '100%'
    $(".tselect-down").select2({
        language: "ru",
        theme: "tselect-md",
    });

    $(".tselect-multi").select2({
        language: "ru",
        theme: "tselect-md",
    });

    function format(state) {
        var originalOption = state.element;
        console.log(state)
        return "<svg  width='24' height='24'><use xlink:href='/svg/sprite_furniture.svg#" + state.id + "'></use></svg> " + state.text;
    }

    $(".tselect-md-icon").select2({
        language: "ru",
        theme: "tselect-md",
        templateResult: format,
        templateSelection: format,
        escapeMarkup: function (m) {
            return m;
        }
    });


    $(".tselect-down-sm").select2({
        language: "ru",
        theme: "tselect-sm",
    });

    $(".tselect-multi-sm").select2({
        language: "ru",
        theme: "tselect-sm",
    });

    //Быстрый просмотр
    $(document).on('click', '.js_quickview_btn', function () {
        $(this).parent().find('input.d-none').click();
    });


    //Добавить поле - страница тип товара
    (function () {
        $(document).on('click', 'form .remove_fields', function (event) {
            $(this).prev('input[type=hidden]').val('1');
            $(this).closest('fieldset').hide();
            return event.preventDefault();
        });

        $(document).on('click', 'form .add_fields', function (event) {
            let regexp, time;
            time = new Date().getTime();
            regexp = new RegExp($(this).data('id'), 'g');
            $(this).before($(this).data('fields').replace(regexp, time));
            return event.preventDefault();
        });

    }).call(this);

    // Потвердил город
    $('.js_city_select_yes').click(function () {
        document.cookie = "city_select=yes";
        location.reload();
    });

    // Выбор типа каталога
    $('.js_catalog_select_grid').click(function () {
        document.cookie = "hit_catalog=grid";
        location.reload();
    });
    $('.js_catalog_select_list').click(function () {
        document.cookie = "hit_catalog=list";
        location.reload();
    });

    // Выбор типа каталога
    $('.js_version_select_mobile').click(function () {
        document.cookie = "is_mobile=mobile; path=/; expires=Tue,";
        location.reload();
    });
    $('.js_version_select_desktop').click(function () {
        document.cookie = "is_mobile=desktop; path=/; expires=Tue,";
        location.reload();
    });

    // ФИЛЬТРАЦИЯ

    $('.js_hide_filter').click(function () {
        $(this).toggleClass('hide');
    });

    //КНОПКА НАВЕРХ
    $(window).scroll(function () {
        if ($(this).scrollTop() != 0) {
            $('.to-top').fadeIn();
        } else {
            $('.to-top').fadeOut();
        }
    });

    $('.to-top').click(function () {
        $('body,html').animate({scrollTop: 0}, 800);
    });


    //МАКСА ТЕЛЕФОНА
    function setCursorPosition(pos, elem) {
        elem.focus();
        if (elem.setSelectionRange) elem.setSelectionRange(pos, pos);
        else if (elem.createTextRange) {
            let range = elem.createTextRange();
            range.collapse(true);
            range.moveEnd("character", pos);
            range.moveStart("character", pos);
            range.select()
        }
    }

    function mask(event) {
        let matrix = "+7 (___) ___-__-__",
            i = 0,
            def = matrix.replace(/\D/g, ""),
            val = this.value.replace(/\D/g, "");
        if (def.length >= val.length) val = def;
        this.value = matrix.replace(/./g, function (a) {
            return /[_\d]/.test(a) && i < val.length ? val.charAt(i++) : i >= val.length ? "" : a
        });
        if (event.type == "blur") {
            if (this.value.length == 2) this.value = "";
        } else setCursorPosition(this.value.length, this)
    }

    let inputTel = $('input[type="tel"]');
    inputTel.on("input", mask);
    inputTel.on("focus", mask);
    inputTel.on("blur", mask);

    //ВАЛИДАЦИЯ
    $('.js_valid_form').parsley();

    //МЕНЮ
    $(".js_hamburger").click(function () {
        $(this).toggleClass("st_active");
        $('body').toggleClass("st_menu_show");
    });

    $(".js_header_btn").click(function () {
        $('.js_header_btn').removeClass('st_active');
        $(this).addClass("st_active");

        var findDiv = '.' + $(this).data('menu');

        $('.js_header_body').removeClass('st_active');
        $(findDiv).addClass("st_active");
    });

    //ТАЙМЕР АКЦИИ
    function getTimeRemaining(endtime) {
        let t = Date.parse(endtime) - Date.parse(new Date()),
            seconds = Math.floor((t / 1000) % 60),
            minutes = Math.floor((t / 1000 / 60) % 60),
            hours = Math.floor((t / (1000 * 60 * 60)) % 24),
            days = Math.floor(t / (1000 * 60 * 60 * 24));
        return {
            total: t,
            days: days,
            hours: hours,
            minutes: minutes,
            seconds: seconds
        };
    }

    function initializeClock(id, endtime) {
        let clock = document.getElementById(id),
            end_clock = 'deadline-' + id,
            start_clock = id,
            daysSpan = clock.querySelector(".days"),
            hoursSpan = clock.querySelector(".hours"),
            minutesSpan = clock.querySelector(".minutes"),
            secondsSpan = clock.querySelector(".seconds");

        function updateClock() {
            let t = getTimeRemaining(endtime);

            if (t.total <= 0) {
                document.getElementById(start_clock).className = "d-none";
                document.getElementById(end_clock).className = "deadline-message-visible";
                clearInterval(timeinterval);
                return true;
            }

            daysSpan.innerHTML = t.days;
            hoursSpan.innerHTML = ("0" + t.hours).slice(-2);
            minutesSpan.innerHTML = ("0" + t.minutes).slice(-2);
            secondsSpan.innerHTML = ("0" + t.seconds).slice(-2);
        }

        updateClock();
        let timeinterval = setInterval(updateClock, 1000);
    }

    $('.js_countdown').each(function () {
        console.log($(this).data('time'));
        initializeClock($(this).attr('id'), $(this).data('time'));
    });


});

//ПОП-АП ВХОДА И ОТЗЫВЫ
$(function () {
    return $("form#sign_in_user, form#sign_up_user").bind("ajax:success", function () {
        return location.reload();
    }).bind("ajax:error", function () {
        let error_messages = '<div class=\'alert alert-danger\'>Не верный пароль или эл.почта</div>';
        return $(this).closest('.modal').find('.modal-footer').html(error_messages);
    });
});

$(function () {
    return $("form#report_new").bind("ajax:success", function () {
        return location.reload();
    }).bind("ajax:error", function () {
        let error_messages = '<div class=\'alert alert-danger\'>Ошибка, попробуйте еще раз</div>';
        return $(this).closest('.modal').find('.modal-footer').html(error_messages);
    });
});

$('body').on('click', '.js_reviews_rating', function () {
    let rating = $(this).html();
    $(this).parent().attr('data-rating', rating);
    $(this).closest('.js_rating_block').find('.js_reviews_input').val(rating);
});

tippy('[data-tippy-content]', {
    theme: 'light',
    placement: 'top',
    allowHTML: true,
});

tippy('[data-tippy-top]', {
    theme: 'light',
    placement: 'top',
    allowHTML: true,
});

tippy('.js-map', {
    onShow(instance) {
        let $this = instance['reference'];
        // $('.js-map').removeClass('st_active');
        // console.log($this);
        $($this).addClass('st_active');
        $.ajax({
            type: 'GET',
            url: $this.dataset.url,
            cache: false,
            success: function (data) {
                instance.setContent(data);
                // $('body').on('click', '.js-close-map', function () {
                //     hideAll();
                // });
            }
        })
    },
    onHidden(instance) {
        instance.setContent('Загрузка...');
        // Unset these properties so new network requests can be initiated
        instance._src = null;
        instance._error = null;
    },
    placement: 'top',
    theme: 'light',
    allowHTML: true,
    content: 'Загрузка...',
});

tippy('.js-goods', {
    onShow(instance) {
        let $this = instance['reference'];
        $($this).addClass('st_active');
        $.ajax({
            type: 'GET',
            url: '/good/information/' + $this.dataset.goods,
            cache: false,
            success: function (data) {
                instance.setContent(data);
            }
        })
    },
    onHidden(instance) {
        instance.setContent('Загрузка...');
        // Unset these properties so new network requests can be initiated
        instance._src = null;
        instance._error = null;
    },
    placement: 'top',
    theme: 'light',
    allowHTML: true,
    interactive: true,
    interactiveBorder: 50,
    content: 'Загрузка...',
});
/*------------------------------------------------------------------
МОБИЛЬНАЯ ВЕРСИЯ
-------------------------------------------------------------------*/
//--Переключение доставки и оплаты--/
$(document).ready(function () {
    $(document).on('change', '.js_cart_change', function () {
        $(this).next().click();
    });

    $('input:radio[name="payment"]').change(function () {
        $('.option-hidden').hide();
        if ($(this).val() == 'cards') {
            $('#show-cards-info').show();
        }
        if ($(this).val() == 'cash') {
            $('#show-cash-info').show();
        }
    });

    $('input:radio[name="shipping"]').change(function () {
        $('.option-hidden').hide();
        if ($(this).val() == 'delivery') {
            $('#show-delivery-cards').show();
        }
        if ($(this).val() == 'pickup') {
            $('#show-pickup-info').show();
        }
    });
});


$(document).ready(function () {


    //ПОКАЗАТЬ ЕЩЕ В КАТАЛОГЕ
    $('.next_page').on('click', function (e) {
        var url;
        e.preventDefault();
        url = $(this).attr('href');
        return $.getScript(url);
    });

    $('.js-content-item-more').on('click', function () {
        $('.content-item-wrapper').toggleClass('view');
    });


    // $('.ajax-popup-link').magnificPopup({
    //   type: 'ajax',
    //   closeBtnInside: true,
    //     showCloseBtn: true,
    //     callbacks: {
    //         open: function() {
    //             function setCursorPosition(pos, elem) {
    //                 elem.focus();
    //                 if (elem.setSelectionRange) elem.setSelectionRange(pos, pos);
    //                 else if (elem.createTextRange) {
    //                     let range = elem.createTextRange();
    //                     range.collapse(true);
    //                     range.moveEnd("character", pos);
    //                     range.moveStart("character", pos);
    //                     range.select()
    //                 }
    //             }

    //             function mask(event) {
    //                 let matrix = "+7 (___) ___-__-__",
    //                     i = 0,
    //                     def = matrix.replace(/\D/g, ""),
    //                     val = this.value.replace(/\D/g, "");
    //                 if (def.length >= val.length) val = def;
    //                 this.value = matrix.replace(/./g, function (a) {
    //                     return /[_\d]/.test(a) && i < val.length ? val.charAt(i++) : i >= val.length ? "" : a
    //                 });
    //                 if (event.type == "blur") {
    //                     if (this.value.length == 2) this.value = "";
    //                 } else setCursorPosition(this.value.length, this)
    //             }

    //             $('body').on('input', 'input[type="tel"]', mask);
    //             $('body').on('focus', 'input[type="tel"]', mask);
    //             $('body').on('blur', 'input[type="tel"]', mask);

    //             $('body').on('click', '.close-btn', function (event) {
    //                 event.preventDefault();
    //                 $.magnificPopup.close();
    //             });
    //         }
    //     }
    // });


    const swiperPopupOne = new Swiper('.swiperPopupOne', {
        spaceBetween: 10,
        modules: [Thumbs, Navigation, Scrollbar, Mousewheel],
        slidesPerView: 3.7,
        direction: 'horizontal',
        mousewheel: true,
        scrollbar: {
            el: ".swiper-scrollbar",
        },
        breakpoints: {
            576: {
                direction: 'horizontal',
                slidesPerView: 4.8,
            },
            768: {
                direction: 'horizontal',
                slidesPerView: 4.8,
            },
            992: {
                slidesPerView: 4.8,
                direction: 'vertical'
            }
        },
    });

    const swiperPopupTwo = new Swiper('.swiperPopupTwo', {
        loop: true,
        slidesPerView: 1,
        modules: [Thumbs, Navigation],
        spaceBetween: 10,
        grabCursor: false,
        thumbs: {
            swiper: swiperPopupOne,
        },
    });

    const compare = new Swiper('.sw-compare', {
        slidesPerView: 1.5,
        modules: [Scrollbar],
        spaceBetween: 20,
        breakpoints: {
            576: {
                slidesPerView: 2.2
            },
            992: {
                slidesPerView: 2.2
            },
            1200: {
                slidesPerView: 3.5
            }
        },
        scrollbar: {
            el: '.sw-scrollbar-compare',
            draggable: true,
        },
    });

    const compareOne = new Swiper('.sw-compare-one', {
        slidesPerView: 1,
        modules: [Pagination],
        spaceBetween: 10,
        pagination: {
            el: ".sw-pagination-compare-one",
            type: "fraction",
        },
    });

    const compareTwo = new Swiper('.sw-compare-two', {
        slidesPerView: 1,
        modules: [Pagination],
        spaceBetween: 10,
        pagination: {
            el: ".sw-pagination-compare-two",
            type: "fraction",
        },
    });

    const compareTab = new Swiper('.sw-compare-tab', {
        slidesPerView: "auto",
        spaceBetween: 12,
    });

    var tileSlider = $('.sw-init');
    tileSlider.each(function () {
        let name = $(this).data('name'),
            slide = $(this).data('slide'),
            autoH = $(this).data('autoheight'),
            sName = '',
            slidename = $(this).data('slidename'),
            sm = 0,
            md = 0,
            lg = 0,
            xl = 0;

        console.log(sName);

        switch (slide) {
            case 1:
                sm = 1;
                md = 1;
                lg = 1;
                xl = 1;
                break;
            case 1.5:
                sm = 1.5;
                md = 1.5;
                lg = 1.5;
                xl = 1.5;
                break;
            case 1.7:
                sm = 1.7;
                md = 1.7;
                lg = 1.7;
                xl = 1.7;
                break;
            case 2.5:
                sm = 2.5;
                md = 2.5;
                lg = 2.5;
                xl = 2.5;
                break;
            case 2:
                sm = 1;
                md = 1;
                lg = 2;
                xl = 2;
                break;
            case 3:
                sm = 1;
                md = 2;
                lg = 2;
                xl = 3;
                break;
            case 4:
                sm = 2;
                md = 3;
                lg = 3;
                xl = 4;
                break;
            case 5:
                sm = 2;
                md = 3;
                lg = 4;
                xl = 5;
                break;
            case 6:
                sm = 2;
                md = 3;
                lg = 4;
                xl = 5;
                break;
        }

        var mySwiper = new Swiper(this, {
            modules: [Navigation, Scrollbar, Mousewheel, Pagination],
            slidesPerView: sm,
            spaceBetween: 20,
            autoHeight: autoH,
            pagination: {
                el: '.' + name + '-pagination',
                bulletClass: 'bullet',
                bulletActiveClass: 'active',
                clickable: 'true',
            },
            navigation: {
                nextEl: '.' + name + '-next',
                prevEl: '.' + name + '-prev',
            },
            scrollbar: {
                el: '.' + name + '-scrollbar',
                hide: false,
            },
            breakpoints: {
                576: {
                    slidesPerView: md,
                },
                768: {
                    slidesPerView: lg,
                },
                992: {
                    slidesPerView: xl,
                },
                1200: {
                    slidesPerView: slide,
                }
            },
        });
    });

    //---- Слайдер, десктоп, меню ----//
    var swiperMenu = new Swiper(".sw-menu", {
        slidesPerView: "auto",
        modules: [Navigation],
        spaceBetween: 20,
        navigation: {
            nextEl: ".sw-menu-next",
            prevEl: ".sw-menu-prev",
        },
    });

    var swiperMenu = new Swiper(".sw-menu-room", {
        slidesPerView: "auto",
        modules: [Navigation],
        spaceBetween: 20,
        navigation: {
            nextEl: ".sw-menu-room-next",
            prevEl: ".sw-menu-room-prev",
        },
    });

    //Эмитация клика на кнопку submit
    $('body').on('change', '.js_click_to-btnsubmit', function () {
        $(this).closest('.js_btnsubmit_parrent').find('.js_btnsubmit').trigger('click');
    });

    //Сетка дл идей
    if (document.querySelector('.grid-idea')) {
        var Masonry = require('masonry-layout');

        var msnry = new Masonry('.grid-idea', {
            itemSelector: '.grid-item',
            columnWidth: '.grid-sizer',
            horizontalOrder: true
        });
    }

    var msnryPost = $('.msnry-init');
    msnryPost.each(function () {
        let id = $(this).data('msnryid');

        var msnry = new Masonry(this, {
            itemSelector: '.grid-item-' + id,
            columnWidth: '.grid-sizer-' + id,
            horizontalOrder: true
        });
    });

    /*-- noUiSlider - настройка --*/
    if (document.querySelector('.ui-slider-init')) {
        var uiSlider = $('.ui-slider-init');

        uiSlider.each(function () {
            let id = $(this).data('us-name'),
                sliderMin = $(this).data('minimum'),
                sliderMax = $(this).data('maximum'),
                uiSliderMinInput = $('#ui-slider-' + id + '-min'),
                uiSliderMaxInput = $('#ui-slider-' + id + '-max');


            var formatForSlider = {
                from: function (formattedValue) {
                    return Math.round(formattedValue);
                },
                to: function (numericValue) {
                    return Math.round(numericValue);
                }
            };

            noUiSlider.create(this, {
                start: [sliderMin, sliderMax],
                step: 10,
                keyboardSupport: true,
                format: formatForSlider,
                connect: true,
                range: {
                    'min': sliderMin,
                    'max': sliderMax
                },
            });

            this.noUiSlider.on('update', function (values, handle) {
                var value = values[handle];
                if (handle) {
                    uiSliderMaxInput[0].value = value;
                } else {
                    uiSliderMinInput[0].value = value;
                }
            });

            uiSliderMinInput[0].addEventListener('change', function () {
                $('.ui-slider-init[data-us-name=' + id + ']')[0].noUiSlider.set([this.value, null]);
            });

            uiSliderMaxInput[0].addEventListener('change', function () {
                $('.ui-slider-init[data-us-name=' + id + ']')[0].noUiSlider.set([null, this.value]);
            });


        });
    }

    /* Мобильное меню, открыть\закрыть */
    $('.hamburger').click(function () {
        $('.hamburger-menu').addClass('open');
        $('body').addClass('overflow-hidden');
    });

    $('.hamburger-menu__close').click(function () {
        $('.hamburger-menu').removeClass('open');
        $('body').removeClass('overflow-hidden');
    });

    /* Мобильное меню, переключение первого уровня */
    $('.hamburger-level1__item').click(function () {
        let id = $(this).data('level1'),
            content = $('.hamburger-level2__item[data-level1=' + id + ']');

        $('.hamburger-level1__item.active').removeClass('active');
        $('.hamburger-level2__item.active').removeClass('active');

        $(this).addClass('active');
        content.addClass('active');
    });

    /* Мобильное меню, переключение второго уровня */
    $('.hamburger-down').click(function () {
        let id = $(this).data('level2'),
            content = $('.hamburger-level21[data-level2=' + id + ']');

        $('.hamburger-level2__item.active').addClass('d-none');
        content.addClass('open');
    });

    $('.hamburger-back').click(function () {
        $('.hamburger-level2__item.active').removeClass('d-none');
        $(this).parent('.hamburger-level21').removeClass('open');
    });

    $('.top-menu__down1').click(function () {
        let id = $(this).data('down1'),
            content = $('.top-menu__content1[data-down1=' + id + ']');

        $('.top-menu__content1.active').removeClass('active');
        $('.top-menu__down1 span').removeClass('active');

        $(this).find('span').addClass('active');
        content.addClass('active');
    });


    /* Одинаковая высота для фото идей в масонри */
    // $('.js-post-height').each(function(){
    //     let he = $(this).height();
    //     $(this).siblings().css( "height", he );
    // });

    var swCardSwipeIdea = new Swiper(".sw-CardSwipeIdea", {
        modules: [EffectCards],
        effect: "cards",
        cardsEffect: {
            perSlideOffset: 7,
            perSlideRotate: 0,
            slideShadows: false,
            rotate: true,
        },
        grabCursor: true,
    });

    $('.sidebar-filter__btn').click(function () {
        $('.sidebar-filter').addClass('open');
    });

    $('.sidebar-filter__close').click(function () {
        $('.sidebar-filter').removeClass('open');
    });

    $('.js-tab-link').click(function () {
        //e.preventDefault();

        let id = $(this).data('tab-link'),
            content = $('.js-tab-content[data-tab-content=' + id + ']');

        $('.js-tab-link.active').removeClass('active');
        $('.js-tab-content.active').removeClass('active');

        $(this).addClass('active');
        content.addClass('active');

    });

    $('.js-close-alerts').click(function () {
        $('.alerts-message').removeClass('show');
    });

    const container = document.querySelector(".notice");

    if (document.cookie.indexOf("notice=") == true) {
        container.classList.remove("show");
        console.log('YES');
    }

    if (document.querySelector(".notice")) {
    const apply = container.querySelector(".js-notice-ok");
    apply.addEventListener("click", () => {
        container.classList.remove("show");
        document.cookie = "notice=true; max-age=2592000; path=/";
    });
    };

});


//Новые стили
require("packs/new")
